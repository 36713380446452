import React, { useState } from 'react';
import CSSModules from 'react-css-modules';
import styles from './unitList.css'
import { transL, transS } from '../../../../services/helpers/lang';
import { rights } from '../../../../../configs/appConfig';
import { Icon_ArrowDown, Icon_Doc } from '../../../../components/svglib/svglib';
import ShowDocStatus from './ShowDocStatus';

const unitHelper = unit => {
    const isDoc = !!unit.orig,
      contentType = unit.contentType,
      isFolder = !isDoc && !contentType,
      hasChildren = !!unit.folders?.length,
      iAmInternal = unit.integrated & rights.INTERNAL,
      canEditTeams = unit.integrated & (rights.ADMIN_COMPANY + 
        rights.ADMIN_UNIT + rights.CREATOR + rights.MANAGE_UNIT) ||
        !iAmInternal && unit.integrated & rights.TEAM_UNIT,
      isInternal = unit.members && iAmInternal,
      hasExternals = unit.members && (!isInternal || 
        unit.rights.find(r => (r & rights.EXTERNAL))),
      inGroup = iAmInternal && !unit.editorsgroup ||
        unit.editorsgroup && !iAmInternal,  // only for docs
      docProps = unit.orig && {
        status: unit.orig === 6 ? 'orig-collaboration' :
          unit.orig === 6 ? (inGroup ? 'orig-finByThem' : 'orig-finByUs'):
          unit.orig === 24 ? 'orig-signed' :
          unit.orig === 25 ? 'orig-signing' :
          unit.orig === 23 ? 'orig-signThem' :
          unit.orig === 22 ? 'orig-signUs' :
          unit.orig === 12 ? 'orig-final' :
          unit.orig === 302 ? 'orig-draft' :
          inGroup ? 'orig-internal' : 'orig-external',
        //approvable: iAmInternal && unit.rights.find(r => (r & rights.APPROVE)),
        amIApprover: iAmInternal && unit.integrated & rights.APPROVE,
        editor: unit.editor,
        edited: unit.edited,
        approvalRequired: false,  //! todo
      }
    //   docHere = unit.orig && [1, 3, 4, 7, 10, 13, 20, 21, 50, 302].includes(unit.orig),
    //   docThere = unit.orig && [2, 8, 9, 14].includes(unit.orig),
    //   docBoth = unit.orig && [6, 12, 22, 23, 24].includes(unit.orig)
      
    return {
      isDoc, contentType, isFolder, hasChildren, iAmInternal, canEditTeams,
      isInternal, hasExternals, inGroup, docProps
    }
  }

const ShowElement = ({unit, level, showParams}) => {
    const {isDoc, contentType, isFolder, hasChildren, iAmInternal, canEditTeams,
        isInternal, hasExternals, inGroup, docProps} = unitHelper(unit),
      {firstColumnStyle, unitsStruc} = showParams,
      padStyle = {marginLeft: `${level*40}px`},
      [isOpen, setIsOpen] = useState(hasChildren && unitsStruc.openFolders.has(unit.loc_id))
    

    const toggleArrow = (el, id) => {
        const target = el.parentElement.parentElement
        if (isOpen) {
          unitsStruc.openFolders.delete(id)
          target.dataset.closed = "1"
        } else {
          unitsStruc.openFolders.add(id)
          target.dataset.closed = ""
        }
        setIsOpen(!isOpen)
      }
    
    let visibleTitle = unit.title, visibleExtension = ''
    if (contentType) {
      const name = visibleTitle.split('.');
      visibleExtension = name.pop().toUpperCase();
      visibleTitle = name.join('.');
    }

    return <div styleName='unit' data-closed={isOpen ? "" : "1"}>
        <div styleName='unit-child unit-flex' style={firstColumnStyle}>
          <div styleName='unit-child unit-tiny' style={padStyle}>
            {isFolder ? 
              <img src="/images/icons-folder.png" width="13"  height="13" />
              : isDoc ? 
              <Icon_Doc />
              : <span styleName='unit-extension'>visibleExtension</span>}
          </div>
          <div styleName='unit-child unit-title'>
            {visibleTitle}
          </div>
          {hasChildren && <div styleName='unit-child unit-arrow'
            onClick={ e => toggleArrow(e.target, unit.loc_id)}          
          >
            <Icon_ArrowDown flip={isOpen} />
          </div>}
        </div>

        <div styleName='unit-child'>
          {transS(hasExternals ? 'Unspecified Companies' :
            isInternal ? 'Internal' 
            : canEditTeams && <span className='dev-button-std' styleName='add-teams'>
                {transS('Add Teams')}
            </span>)
          }
        </div>

        <div styleName='unit-child unit-status'>
          {isDoc ? <ShowDocStatus 
            status={docProps.status}
            approvalRequired={docProps.approvalRequired}
          /> 
          : isFolder ? '' : unit.shared ? 
            <div className='dev-button-std' styleName='orig-external'>
                {transS('Shared')}
            </div>
             : ''}
        </div>

        <div styleName='unit-child unit-narrow'>
          
        </div>
      
        <div styleName='unit-child unit-wide'>
          
        </div>
        <div styleName='unit-child unit-last'>
          <div>^</div>
          <div>P</div>
          <div>M</div>
        </div>      
    </div>
  }

  export default CSSModules(ShowElement, styles, {allowMultiple: true});