import { socketConstants } from "../../../configs/appConfig";
const initialState = {
    unitsList: new Map(), 
    unitsTree: [],
    unitsStruc: null,
    knownUsers: new Map(),
    company: null,
    companyTree: null,
    companyStruc: null,
    currentUnit: null,
    loaded: null
  };

export default (state = initialState, action) => {
  switch (action.type) {
  case unitConstants.SIMPLE:
    return {
      ...state,
      ...action.data,
    };
  case socketConstants.S_GATHER_ALL:
    return gatherInitial(action.data, state)
  default:
    return state
  }

}

export const unitConstants = {
  SIMPLE: 'SIMPLE',
}

const gatherInitial = (data, state) => {
  // data is object with    
      // "company": "025fc7bf1aff54ac7e818b1e10",
      // "tree": [ -- company tree
      //     {
      //         "loc_id": "021728739262103",
      //         "ancestor": "025fc7bf1aff54ac7e818b1e10",
      //         "folders": [
      //             null                        -- team ids
      //         ],
      //         "members": [
      //             "025b716fed6003183c2640af97" --user ids
      //         ],
      //         "rights": [
      //             5                            -- rights simmetric
      //         ],
      //         "team_name": "R&D",             -- special _deactivated _invited
      //         "team_type": 0,                   -- 1 is company
      //         "props": null                     -- for company
      //     },
      //     ... sample props for company          
      //         "props": {
      //             "address": {
      //                 "zip": "248000",
      //                 "state": "",
      //                 "country": "Russia",
      //                 "address1": "Suvotova St., 46",
      //                 "address2": ""
      //             }          
      // ],
      // "companyUsers": [
      //     {
      //         "userid": "025b716fed6003183c2640af97",
      //         "firstname": "Theodor",
      //         "lastname": "Oberländer",
      //         "email": "wldmr@everest.kaluga.ru",
      //         "isactivated": true,
      //         "avatar": "/avatars/863c10b02d260fe98ff94027c1a466d21605475591283.png"
      //     }
      //     ...
      // ],
      // "units": [  -- sorted by abcestors and created
      //     {
      //         "ancestor": null,
      //         "loc_id": "0263a54902cb4d2929c489c5c6",
      //         "title": "New Project 5",
      //         "ucreated": "1671776514896",
      //         "description": null,
      //         "integrated": 18080, -- rights of current user
      //         "version": null,
      //         "created": null,
      //         "orig": null,
      //         "editor": null,
      //         "editorsgroup": null,
      //         "members": [  -- teams and users
      //             "02651067c068d21c2e3fddf10b", 
      //             "025b716fed6003183c2640af97",
      //             "025db2ced7c7b3c72531cc7732",
      //             "025bf9079d6003183c2640afd0",
      //             "025bf6aa796003183c2640afce"
      //         ],
      //         "rights": [   -- of teams and users (integrated)
      //             17920,
      //             18080,
      //             17408,
      //             149504,
      //             149504
      //         ],
      //         "shared": null,
      //         "approved": null
      //     },  
      //     ...  
      // ],
      // "knownUsers": [
      //     {
      //         "userid": "025b6d7e076003183c2640af90",
      //         "firstname": "Peter",
      //         "lastname": "Andreev",
      //         "email": "peter@everest.kaluga.ru",
      //         "isactivated": true,
      //         "avatar": "/avatars/887daf494194db58b9d22dc4983d01231593850629630.jpg"
      //     },
      //     ...
      // ]

    // knownUsers appended with company Users with property myCompany
    const {company, companyTree, unitsTree } = data,
      common = company ? 
        [...data.knownUsers, ...data.companyUsers] : data.knownUsers,      
      knownUsers = new Map(common.map(item => [item.userid, item])),
      unitsList = new Map(unitsTree.map(item => [item.loc_id, item]))
    
    unitsTree.forEach(el => {
      if (!el.folders) el.folders = []
      if (!el.ancestor) return
      const parent = unitsList.get(el.ancestor)
      if (!parent.folders) parent.folders = [el.loc_id]
      else parent.folders.push(el.loc_id)
    });    
    
    const unitsStruc = prepareTree(unitsTree, state.unitsStruc),
      companyStruc = prepareTree(companyTree,  state.companyStruc)
  
    return {
      ... state,
      unitsList,
      unitsTree,
      unitsStruc,
      knownUsers,
      company,
      companyTree,
      companyStruc,
      loaded: true
    }
}

const prepareTree = (tree, struc, openLevels = 1) => {
  const calcLevel = (parent, level = 0) => {
    const elements = tree.filter(t => t.ancestor === parent)
    let maxLevel = level
    elements.forEach(e => {
      if (!e.folders.length) return
      const calced = calcLevel(e.loc_id, level+1)
      if (calced > maxLevel) maxLevel = calced
    })
    return maxLevel
  }
  const maxLevel = calcLevel(null),
    openFolders = struc ? struc.openFolders : new Set()
  for(let i=0; i<openLevels; i++)
    tree.filter(t => t.ancestor === null || openFolders.has(t.ancestor))
      .forEach(s => openFolders.add(s.loc_id))
  return {maxLevel, openFolders}
}
