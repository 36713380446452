import React from 'react';
import { useDispatch, useSelector } from 'react-redux';


export const Tree = ({parent = null, ShowElement, showParams, level = 0}) => {
    const {unitsTree, unitsList} = useSelector(state => state.unitReducer),
      elements = unitsTree.filter(t => t.ancestor === parent)
    return elements.map(t =>
      <div key={t.loc_id} data-tree="1">
          <ShowElement unit={t} level={level} showParams = {showParams} />
          {!!t.folders.length &&
          <Tree 
              parent={t.loc_id}
              ShowElement={ShowElement}
              level={level + 1}
              showParams = {showParams}
          />
          }
      </div>
    )
  }
