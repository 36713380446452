import React from 'react';
import { useSelector } from 'react-redux';
import CSSModules from 'react-css-modules';
import styles from './unitList.css'
import { transL, transS } from '../../../../services/helpers/lang';
import Spinner from '../../../../components/spinner/Spinner';
import ListHeader from "../../components/ListHeader/ListHeader";
import Units from './Units';

const UnitList = ()  => {
  const {loaded} = useSelector(state => state.unitReducer),
    {user} = useSelector(state => state.userReducer)
  
  return <>
    <ListHeader currentOption={0} user={user} />
    <div styleName="wrapper">
      <div className='h1'>{transS("Documents")}</div>
      {loaded ? <Units /> :
      <Spinner />}        
    </div>   
  </>       
}

export default CSSModules(UnitList, styles, {allowMultiple: true});

