import React from 'react';

const color_primary= "#157EFB", 
color_primary_hover= "#1DA6E5", 
color_primary_hover__dark= "#0368e0",
color_primary_semi= "#b7d6fc",
color_black= "#212121",
color_darkGray= "#606060",
color_gray= "#ACACAC", 
color_arrowGray= "#979797", 
color_gray_hover= "rgba(172, 172, 172, 0.75)",
color_lightGray2= "#DEDEDE",
color_lightGray3= "#F7F7F7",
color_lightGray= "#EEEEEE",
color_white= "#FFF",
color_green= "#00AB26", 
color_green_hover= "rgba(0, 171, 38, 0.75)",
color_darkRed= "#D0021B", 
color_darkRed_hover= "rgba(208, 2, 27, 0.75)",
color_lightRed= "#FF6C6C",
color_orange= "#F89540", 
color_orange_hover= "#FFB85C",
color_orange_semi= "#F8954080",
color_yellow_light= "rgba(255, 233, 0, 0.2)",
color_yellow_semi= "rgba(255, 233, 0, 0.5)",
color_yellow_frame= "#ffeb3b38"


export const Icon_Doc = React.memo(({ color = color_gray, width = 11, height = 13 }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 11 13"
      width={width}
      height={height}
      stroke={color}
      fill="none" 
      fillRule="evenodd"
    >
      <path d="M1 1h9v11H1V1zm2 2h5M3 5h5M3 7h5" />
    </svg>
  ));

  export const Icon_ArrowDown = React.memo(({ color = color_arrowGray, 
    width = 10, height = 12, flip }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 10 12"
      width={width}
      height={height}
      stroke={color}
      fill="none" 
      fillRule="evenodd" 
      strokeLinecap="square"
      style = {flip ? {transform: "scaleY(-1)"} : {}}
    >
      <path d="M5 7.958 9.222 4M.778 4 5 7.958" />
    </svg>
  ));
  