import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CSSModules from 'react-css-modules';
import styles from './unitList.css'
import { transL, transS } from '../../../../services/helpers/lang';
import { rights } from '../../../../../configs/appConfig';
import { Tree } from './Tree';
import ShowElement from './ShowElement';


const Units = () => {  
    const {unitsTree, unitsStruc} = useSelector(state => state.unitReducer)
    if (!unitsTree.length) return <div></div>
    const firstWidth = unitsStruc.maxLevel*40 + (unitsStruc.maxLevel ? 200 : 240),
      firstColumnStyle = {width: `${firstWidth}px`}
    const showParams = {firstColumnStyle, unitsStruc}
         
  
    return <div>
      <div styleName='unit'>
        <div styleName='unit-header' style={firstColumnStyle}>
          {transS('Documents')}
        </div>
  
        <div styleName='unit-header'>
          {transS('Counterparty & Teams')}
        </div>
        <div styleName='unit-header'>
          {transS('Status')}
        </div>
        <div styleName='unit-header unit-narrow'>
          {transS('Latest Activity')}
        </div>
        <div styleName='unit-header unit-wide'>
          {transS('Parameters')}
        </div>
      </div>
      <Tree ShowElement={ShowElement} showParams = {showParams} />      
    </div>
  }

export default CSSModules(Units, styles, {allowMultiple: true});