import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './unitList.css'
import { transL } from '../../../../services/helpers/lang';

const ShowDocStatus = ({status, approvalRequired}) => {
    const statusRefine = approvalRequired ? 'orig-approval' : status,
      append = transL('app_' + status)
    return <>
      <div className='dev-button-std' styleName={statusRefine}>
          {transL(status)}
      </div>
    </>
  }
  
export default CSSModules(ShowDocStatus, styles, {allowMultiple: true});  